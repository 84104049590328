export const TITLE = [
  "home:comingSoon.title",
  "{{shortName}} stats coming soon!",
];

export const DESCRIPTION = [
  "home:comingSoon.fortnite.content.body",
  `Blitz.gg's {{shortName}} Stats Tracker and Leaderboards are on the horizon! Blitz.gg is committed to delivering comprehensive {{shortName}} stats, global leaderboards, as well as the freshest news about the game. Our upcoming leaderboards will rank players based on their skill, and provide a detailed breakdown of stats by ranks, weapons, and more. You can expect to view individualized player stats profiles and detailed tactical insights. Compare your stats to your friends, rivals, and top-tier streamers. Blitz.gg's mission is to equip you with all the information you need to stay informed and one step ahead of the competition. Stay alert for the launch of our most anticipated tool to date!`,
];

export const EMAIL = "home:comingSoon.email.cta";

export const FOOTER_TITLE = [
  "home:comingSoon.seo.title",
  "About our {{shortName}} Stats",
];

export const FOOTER_DESC = [
  "home:comingSoon.cs2.seo.body",
  `{{shortName}} Stats Tracker and {{shortName}} Leaderboards are your gateway to excelling in the game. We offer extensive regional and global {{shortName}} leaderboards, plus the freshest game updates. Our {{shortName}} profiles are enriched with stats by match, characters, and classes, empower you to measure up to top players globally. Blitz.gg ensures you stay informed, primed, and ahead with our {{shortName}} Tracker.`,
];
